var dayjs = require('dayjs')
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')
dayjs.extend(timezone)
dayjs.extend(utc)

const convertTimestampToHumanDate = (date, timeZone = 'Europe/Berlin') => {
  dayjs.tz.setDefault(timeZone)
  var result = dayjs.tz(parseInt(date)).format('DD.MM.YY HH:mm:ss')
  return result;
};

module.exports = convertTimestampToHumanDate;