import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import Home from '../views/Home.vue'
import StartList from '../views/StartList.vue'
import ResultList from '../views/ResultList.vue'
import TournamentStartListDMM from '../views/TournamentStartListDMM.vue'
import TournamentResultListDMM from '../views/TournamentResultListDMM.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/:lid',
    name: 'Home2',
    component: Home
  },
  {
    path: '/:lid/:groupid',
    name: 'Home2',
    component: Home
  },
  {
    path: '/:lid/:groupid/:roundid',
    name: 'Home2',
    component: Home
  },
  /* 
  {
    path: '/startlist',
    name: 'StartList',
    component: StartList
  },
  {
    path: '/startlist/:lid',
    name: 'StartList',
    component: StartList
  },
  {
    path: '/startlist/:lid/:groupid',
    name: 'StartList',
    component: StartList
  },
  {
    path: '/startlist/:lid/:groupid/:roundid',
    name: 'StartList',
    component: StartList
  },
  {
    path: '/resultlist',
    name: 'ResultList',
    component: ResultList
  },
  {
    path: '/resultlist/:lid',
    name: 'ResultList',
    component: ResultList
  },
  {
    path: '/resultlist/:lid/:groupid',
    name: 'ResultList',
    component: ResultList
  },
  {
    path: '/resultlist/:lid/:groupid/:roundid',
    name: 'ResultList',
    component: ResultList
  }, */
  {
    path: '/startlist/:lid/:gid/:rid',
    name: 'TournamentStartListDMM',
    component: TournamentStartListDMM
  },
  {
    path: '/resultlist/:lid/:groupid/:roundid',
    name: 'TournamentResultListDMM',
    component: TournamentResultListDMM
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  next()
})

export default router
