<template>
  <ion-page>
    <ion-content>
      <div id="tournamentScreens">
        <div class="">
            <div class="head">  
                <div class="row">
                    <div class="col-10">
                        <h2>{{ leagueName }}</h2>
                        <h2>Startliste Flight {{selectedFlight==0?'A':'B'}} - Runde {{parseInt(selectedRound)+1}}</h2>
                    </div>
                    <div class="col-2">
                        <span class="logo"></span>
                    </div>
                </div>
            </div>

            <div class="">
                <div class="row text-center" style="background-color: #bcc4cb">
                    <div class="col-1">
                        TEE
                    </div>
                    <div class="col-1">
                        ZEIT
                    </div>
                    <div class="col-10">
                        SPIELGRUPPE
                    </div>
                </div>

                <div class="row startListRow" v-for="(group, i) in displayedItem" :key="i" >
                    <div class="col-1 text-center startListCol">
                    {{group.tee}}
                    </div>
                    <div class="col-1 text-center startListCol">
                    {{ convertTimestampToHumanDate(group.start_time).split(' ')[1].substring(0, 5) }}
                    </div>
                    <div class="col-10 startListCol">
                        <div class="">
                            <div class="row">
                                <div :id="'person_id_'+player.person_id" class="col-sm startListCol" v-for="(player, j) in group.players" :key="j" :style=player.color >
                                    {{ player.name }} {{ player.familyname }} <span class="hcp_font" >({{ player.hcp==59?'--':parseFloat(player.hcp).toFixed(1) }})<br>{{ player.LEAGUE_TEAM_NAME }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row startListRow" v-for="n in perPage - displayedItem.length" :key="n">
                </div>
                
            </div>
            
            <div>
                <div class="foot">
                    
                    <div class="row">
                        <div class="col-10">
                            <span>Deutscher Golf Verband e.V.<br>{{lastUpdate}}</span>
                        </div>
                        <div class="col-2">
                            <br>
                            <div>
                                
                                <button type="button" :disabled="page<=1" @click="page--"> 
                                    <ion-icon :ios="chevronBackOutline" :md="chevronBackOutline"></ion-icon>
                                </button>
                                
                                <button type="button" @click="isPaused = !isPaused">  
                                    <ion-icon v-if="isPaused" :ios="playOutline" :md="playOutline"></ion-icon>
                                    <ion-icon v-else :ios="pauseOutline" :md="pauseOutline"></ion-icon>
                                </button>

                                <button type="button" :disabled="page >= pages.length" @click="page++">  
                                    <ion-icon :ios="chevronForwardOutline" :md="chevronForwardOutline"></ion-icon>
                                </button>
                                
                                    <ion-icon :ios="settingsOutline" :md="settingsOutline" 
                                    type="button"
                                    id="dropdownMenuButton2"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    ></ion-icon>
                                    
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                                        <li>Flight:                                            
                                            <select class="custom-select" v-model="selectedFlight" @Change=changeFlight($event.target.value)>
                                                <option value="0">A</option>
                                                <option value="1">B</option>
                                            </select>
                                        </li>
                                        <li>Round:                                            
                                            <select class="custom-select" v-model="selectedRound" @Change=changeRound($event.target.value)>
                                                <option value="0">1</option>
                                                <option value="1">2</option>
                                                <option value="2">3</option>
                                                <option value="3">4</option>
                                            </select>
                                        </li>
                                        <li>pro Seite: 
                                            <select class="custom-select" v-model="perPage">
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                                <option value="11">11</option>
                                                <option value="12">12</option>
                                            </select>
                                        </li>
                                        <li>Zeit pro Seite:                                            
                                            <select class="custom-select" v-model="timer">
                                                <option value="1">1</option>
                                                <option value="5">5</option>
                                                <option value="8">8</option>
                                                <option value="10">10</option>
                                                <option value="15">15</option>
                                            </select>
                                        </li>
                                    </ul>
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>
        </div>
      </div>
    </ion-content>
  </ion-page>

</template>


<script>
import { toastController, modalController, loadingController, IonTitle, IonButton, IonToolbar, IonHeader, 
IonMenuButton, IonButtons, IonGrid, IonImg, IonCol, IonRow, IonItemDivider, IonSearchbar, IonCheckbox,
IonApp, IonContent, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle, 
IonNote, IonRouterOutlet, IonSplitPane, IonSelect, IonSelectOption, IonAvatar, IonFabList, 
IonFabButton, IonFab, IonCardContent, IonCardHeader, IonCardTitle, IonCard, IonPage, isPlatform } from '@ionic/vue';
import { defineComponent, ref, watch } from 'vue';
import { useQuery, useResult } from '@vue/apollo-composable'
import { apolloClient } from '../main.js';

import convertTimestampToHumanDate from "../helpers/convertTimestampToHumanDate.js";

import findStartListQueryFile from '../graphql/findStartList.query.gql'
import { useRoute, useRouter } from 'vue-router';
import { chevronBackOutline, chevronForwardOutline, arrowDownSharp, arrowUpSharp, playOutline, pauseOutline, settingsOutline } from 'ionicons/icons';
import * as dayjs from 'dayjs';
import VueScrollingTable from "vue-scrolling-table"
import "/node_modules/vue-scrolling-table/dist/style.css"


export default defineComponent({
  name: 'Home',
  components: {
    IonApp, IonImg, IonAvatar, IonPage,
    IonContent, 
    IonIcon, 
    IonItem, 
    IonLabel, VueScrollingTable,
    IonList, 
    IonListHeader, 
    IonMenu, 
    IonMenuToggle, 
    IonNote, 
    IonRouterOutlet, 
    IonSplitPane,
    IonSearchbar,
    IonItemDivider,
    IonGrid, 
    IonCol, 
    IonRow,
    IonTitle, IonButton, IonToolbar, IonHeader, IonMenuButton, IonButtons, IonSelect, IonSelectOption,
    IonFabList, IonFabButton, IonFab, IonCardContent, IonCardHeader, IonCardTitle, IonCard, IonCheckbox
  },
  data() {
    return {
        logo: require('@/assets/images/logo/nrwlogo.png'),
        weekDays: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
        leagueName: '',
        startList: [],
      
        page: 1,
        perPage: 8,
        pages: [],

        isPaused: false,
        nextPageIntervalId: 0,
        timer: 8,
        lastUpdate: null
    }
  },
  watch: {
    startList() {
        this.setPages();
    },
    page(){
        this.autoScrollText();
    },
    perPage(){
        this.setPages();
    },
    timer(){
        this.setPages();
    },
  },	
  computed: {
    displayedItem () {
        return this.paginate(this.startList);
    }
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const selectedFlight = ref(parseInt(route.params.groupid))
    const selectedRound = ref(parseInt(route.params.roundid))
    return {
      chevronBackOutline, chevronForwardOutline, dayjs, arrowDownSharp, arrowUpSharp, playOutline, pauseOutline,settingsOutline,
      isPlatform, selectedFlight, selectedRound
    }
  },
  mounted(){    
    this.getStartListData();

    this.nextPageIntervalId = setInterval(() => {
        this.page ++
        
        if(this.page > this.pages.length){
            this.getStartListData()
        }

    }, this.timer * 1000);

  },
  methods: {
    convertTimestampToHumanDate,
    changeFlight(flight) {
      this.$router.push({ path: '/startlist/' + this.$route.params.lid + '/' + flight  + '/' + this.$route.params.roundid})
      this.getStartListData()
    },
    changeRound(round) {
      this.$router.push({ path: '/startlist/' + this.$route.params.lid + '/' +  this.$route.params.groupid  + '/' + round})
      this.getStartListData()
    },
    async getStartListData(){
        await apolloClient.query({
          query: findStartListQueryFile,
          variables: {     
              "league_id": parseInt(this.$route.params.lid),
              "group_id": parseInt(this.selectedFlight),
              "round_id": parseInt(this.selectedRound)
          }
        })
        .then((res) => {
            this.startList = []
            this.lastUpdate = convertTimestampToHumanDate(Date.now())

            this.leagueName = res.data.findStartList.leagueName
            // groupBy by start_time
            var listGroupByStartTime = this.groupBy(res.data.findStartList.startList, 'start_time');     
            listGroupByStartTime = Object.entries(listGroupByStartTime)
            
            // groupBy by start_tee
            listGroupByStartTime.forEach(time => {
                var listGroupByStartTee = this.groupBy(time[1], 'start_tee')
                listGroupByStartTee = Object.entries(listGroupByStartTee)
                listGroupByStartTee.forEach(tee => {
                    var teeNr = tee[0]
                    var players = tee[1]
                    var colorNr = 255 
                    var roundTeamId = 0
                    players.forEach(player => {
                        if(player.round_team_id != roundTeamId){
                            colorNr-=40
                            roundTeamId = player.round_team_id
                        }
                        player.color = {backgroundColor: `rgb(`+colorNr+`,`+colorNr+`,`+colorNr+`)`}
                    });
                    this.startList.push({'tee': teeNr, 'start_time': players[0].start_time, 'players': players})
                });
            });
        })
    },
    groupBy(objectArray, property) {
      return objectArray.reduce((acc, obj) => {
          const key = obj[property];
          if (!acc[key]) {
            acc[key] = [];
          }
          // Add object to list for given key's value
          acc[key].push(obj);
          return acc;
      }, {});
    },
    setPages () {
        this.pages = []
        let numberOfPages = Math.ceil(this.startList.length / this.perPage);
        for (let index = 1; index <= numberOfPages; index++) {
            this.pages.push(index);
        }
        this.page=1
        
        clearInterval(this.nextPageIntervalId);
        this.nextPageIntervalId = setInterval(() => {
            if(!this.isPaused){
                this.page ++
                
                if(this.page > this.pages.length){
                    this.getStartListData()
                }
            }
        }, this.timer * 1000);
    },
    paginate (startList) {
        let page = this.page;
        let perPage = this.perPage;
        let from = (page * perPage) - perPage;
        let to = (page * perPage);
        return  startList.slice(from, to);
    },
    autoScrollText() {
        setTimeout(() => {
            this.displayedItem.forEach(group => {
                for (const player of group.players) {
                    var div_id = 'person_id_'+player.person_id
                    
                    var objDiv = document.getElementById(div_id);
                
                    $('#'+div_id).animate({
                        scrollTop: objDiv.scrollHeight
                    }, 5 * 1000);  
                }
            });
        }, 4000);
    }
  }
});


</script>

<style scoped>
.head{
    background-color: #e4e7ea;
    padding: 20px 0px 0px 20px;
}
#tournamentScreens{
    border-radius: 25px;    
    overflow: hidden;    
    border: solid 2px #e4e7ea;
    margin: 3%;
    font: 22px 'Calibri',Arial,Helvetica,Tahoma,Verdana,sans-serif;
}

.logo {
    height: 80px;
    width: 200px;
    background: transparent url(https://dgvscreens.cio.golf/cio/customer/logo.png) no-repeat top left;
    background-size: contain;
}
    

.hcp_font{
  font: 17px 'Open Sans', Arial, Helvetica, Tahoma, Verdana, sans-serif;
}

.startListRow{
    align-content: center;
    align-items: center;
  
    height: 60px;
    border: 1px solid #e4e7ea;
}

.startListCol{
    max-height: 60px;
    overflow: auto;
    border-right: 1px solid #e4e7ea;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.startListCol::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.startListCol {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}


.foot {    
    bottom: 0;
    left: 0;
    right: 0;
    margin-top: -4px;
    padding: 0 25px;
    line-height: 29px;
    
    background: #e4e7ea url(https://dgvscreens.cio.golf/cio/img/cio_logo.png) center center / 640px no-repeat;
    color: #000;
    height: 60px;
}

</style>