<template>
  <ion-page>
    <ion-content>
      <div id="tournamentScreens">
        <div class="">
            <div class="head">  
                <div class="row">
                    <div class="col-10">
                        <h2>{{ leagueName }}</h2>
                        <h2>Flight {{selectedFlight==0?'A':'B'}} - Runde {{parseInt(selectedRound) + 1}}</h2>
                    </div>
                    <div class="col-2">
                        <span class="logo"></span>
                    </div>
                </div>
            </div>

            <div class="">
                <div class="row text-center" style="background-color: #bcc4cb">
                    <div class="col-4">
                        Team
                    </div>
                    <div class="col-1">
                        Punkte
                    </div>
                    <div class="col-2">
                        Ergebnis
                    </div>
                    <div class="col-1">
                        Punkte
                    </div>
                    <div class="col-4">
                        Team
                    </div>
                </div>

                <div v-for="(item, i) in displayedItem" :key="i">
                    <div v-if="item.table=='overview'" class="row bracketsRow">
                      <div class="col-4 text-center bracketsCol">
                        {{ item.team1}}
                      </div>
                      <div class="col-1 text-center bracketsCol">
                        {{ item.team1Points }}
                      </div>
                      <div class="col-2 text-center bracketsCol">
                        {{ item.team1Points }} : {{ item.team2Points }}
                      </div>
                      <div class="col-1 text-center bracketsCol">
                        {{ item.team2Points }}
                      </div>
                      <div class="col-4 text-center bracketsCol">
                        {{ item.team2}}
                      </div>
                    </div>
                    <div v-else-if="item.table=='match'">
                      <div  class="row bracketsRow" :style="item.points1 && this.showScorecard==true?'background-color: #e4e7ea;':''">                    
                        <div class="col-4 text-center">
                          {{ item.players1}}
                        </div>
                        <div class="col-1 text-center bracketsCol">
                          {{ item.points1 }}
                        </div>
                        <div class="col-2 text-center bracketsCol">
                          {{ item.result }}
                        </div>
                        <div class="col-1 text-center bracketsCol">
                          {{ item.points2 }}
                        </div>
                        <div class="col-4 text-center bracketsCol">
                          {{ item.players2}}
                        </div>
                      </div>
                       
                      <div v-if="item.points1 && this.showScorecard" class="table-responsive text-center">
                        <table class="table table-bordered table-sm">
                          <thead>
                            <tr>
                              <td class="col-3" scope="col">Loch</td>
                              <td class="holeCol" scope="col" v-for="(hole, h) in item.numHoles" :key="h" :value="hole">{{ h+1 }}</td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{{item.players1}}</td>
                              <td v-for="(hole, h) in item.numHoles" :key="h" :value="hole">{{ (item.player1Scores[h] -  item.player2Scores[h]) > 0 ? (item.player1Scores[h] - item.player2Scores[h]) + ' auf' : '' }}</td>
                            </tr>
                            <tr>
                              <td>{{item.players2}}</td>
                              <td v-for="(hole, h) in item.numHoles" :key="h" :value="hole">{{ (item.player2Scores[h] - item.player1Scores[h]) > 0 ? (item.player2Scores[h]  - item.player1Scores[h]) + ' auf' : '' }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      
                    </div>
                    <div v-else class="row bracketsRow" >
                    </div>
                </div>

                <div class="row bracketsRow" v-for="n in perPage - displayedItem.length" :key="n">
                </div>
                
            </div>
            
            <div>
                <div class="foot">
                    
                    <div class="row">
                        <div class="col-10">
                            <span>Deutscher Golf Verband e.V.<br>{{lastUpdate}}</span>
                        </div>
                        <div class="col-2">
                            <br>
                            <div>
                                
                                <button type="button" :disabled="page<=1" @click="page--"> 
                                    <ion-icon :ios="chevronBackOutline" :md="chevronBackOutline"></ion-icon>
                                </button>
                                
                                <button type="button" @click="isPaused = !isPaused">  
                                    <ion-icon v-if="isPaused" :ios="playOutline" :md="playOutline"></ion-icon>
                                    <ion-icon v-else :ios="pauseOutline" :md="pauseOutline"></ion-icon>
                                </button>

                                <button type="button" :disabled="page >= pages.length" @click="page++">  
                                    <ion-icon :ios="chevronForwardOutline" :md="chevronForwardOutline"></ion-icon>
                                </button>
                                
                                    <ion-icon :ios="settingsOutline" :md="settingsOutline" 
                                    type="button"
                                    id="dropdownMenuButton2"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    ></ion-icon>
                                    
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                                        <li>Flight:                                            
                                            <select class="custom-select" v-model="selectedFlight" @Change=changeFlight($event.target.value)>
                                                <option value="0">A</option>
                                                <option value="1">B</option>
                                            </select>
                                        </li>
                                        <li>Runde:                                            
                                            <select class="custom-select" v-model="selectedRound" @Change=changeRound($event.target.value)>
                                                <option value="0">1</option>
                                                <option value="1">2</option>
                                                <option value="2">3</option>
                                                <option value="3">4</option>
                                            </select>
                                        </li>                                        
                                        <li>Scores anzeigen:                                       
                                          <button @click="showScorecard=!showScorecard">{{showScorecard?'Ja':'Nein'}}</button>
                                        </li>
                                        <li>pro Seite: 
                                            <select class="custom-select" v-model="perPage">
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                                <option value="11">11</option>
                                                <option value="12">12</option>
                                            </select>
                                        </li>
                                        <li>Zeit pro Seite:                                            
                                            <select class="custom-select" v-model="timer">
                                                <option value="1">1</option>
                                                <option value="5">5</option>
                                                <option value="8">8</option>
                                                <option value="10">10</option>
                                                <option value="15">15</option>
                                            </select>
                                        </li>
                                    </ul>
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { toastController, modalController, loadingController, IonTitle, IonButton, IonToolbar, IonHeader, 
IonMenuButton, IonButtons, IonGrid, IonImg, IonCol, IonRow, IonItemDivider, IonSearchbar, IonCheckbox,
IonApp, IonContent, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle, 
IonNote, IonRouterOutlet, IonSplitPane, IonSelect, IonSelectOption, IonAvatar, IonFabList, 
IonFabButton, IonFab, IonCardContent, IonCardHeader, IonCardTitle, IonCard, IonPage, isPlatform } from '@ionic/vue';
import { defineComponent, ref, watch } from 'vue';
import { useQuery, useResult } from '@vue/apollo-composable'

import { apolloClient } from '../main.js';
import convertTimestampToHumanDate from "../helpers/convertTimestampToHumanDate.js";

import findBracketRoundQueryFile from '../graphql/findBracketRoundNew.query.gql'
import { useRoute, useRouter } from 'vue-router';
import { chevronBackOutline, chevronForwardOutline, arrowDownSharp, arrowUpSharp, playOutline, pauseOutline, settingsOutline } from 'ionicons/icons';
import * as dayjs from 'dayjs';
import VueScrollingTable from "vue-scrolling-table"
import "/node_modules/vue-scrolling-table/dist/style.css"

export default defineComponent({
  name: 'ResultList',
  components: {
    IonApp, IonImg, IonAvatar, IonPage,
    IonContent, 
    IonIcon, 
    IonItem, 
    IonLabel, VueScrollingTable,
    IonList, 
    IonListHeader, 
    IonMenu, 
    IonMenuToggle, 
    IonNote, 
    IonRouterOutlet, 
    IonSplitPane,
    IonSearchbar,
    IonItemDivider,
    IonGrid, 
    IonCol, 
    IonRow,
    IonTitle, IonButton, IonToolbar, IonHeader, IonMenuButton, IonButtons, IonSelect, IonSelectOption,
    IonFabList, IonFabButton, IonFab, IonCardContent, IonCardHeader, IonCardTitle, IonCard, IonCheckbox
  },
  data() {
    return {
      logo: require('@/assets/images/logo/nrwlogo.png'),
      weekDays: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
      leagueName: '',
      allItems: [],
    
      page: 1,
      perPage: 8,
      pages: [],

      isPaused: false,
      nextPageIntervalId: 0,
      timer: 8,
      lastUpdate: null,
      showScorecard: false
    }
  },	
  watch: {
    allItems() {
        this.setPages();
    },
    perPage(){
        this.getBrackets();
    },
    timer(){
        this.setPages();
    }
  },
  computed: {
    displayedItem () {
        return this.paginate(this.allItems);
    }
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const selectedFlight = ref(parseInt(route.params.groupid))
    const selectedRound = ref(parseInt(route.params.roundid))

    return {
      chevronBackOutline, chevronForwardOutline, dayjs, arrowDownSharp, arrowUpSharp, playOutline, pauseOutline, settingsOutline,
      isPlatform, selectedFlight, selectedRound
    }
  },
  mounted(){    
    this.getBrackets();

    this.nextPageIntervalId = setInterval(() => {
        this.page ++
        
        if(this.page > this.pages.length){
            this.getBrackets()
        }

    }, this.timer * 1000);

  },
  methods: {
    convertTimestampToHumanDate,
    changeFlight(flight) {
      this.$router.push('/resultlist/' + this.$route.params.lid + '/' + flight  + '/' + this.$route.params.roundid)
      this.getBrackets()
    },
    changeRound(round) {
      this.$router.push('/resultlist/' + this.$route.params.lid + '/' + this.$route.params.groupid  + '/' + round)
      this.getBrackets()
    },
    setPages () {
        this.pages = []
        let numberOfPages = Math.ceil(this.allItems.length / this.perPage);
        for (let index = 1; index <= numberOfPages; index++) {
            this.pages.push(index);
        }
        this.page=1
        
        clearInterval(this.nextPageIntervalId);
        this.nextPageIntervalId = setInterval(() => {
            if(!this.isPaused){
                this.page ++
                
                if(this.page > this.pages.length){
                    this.getBrackets()
                }
            }
        }, this.timer * 1000);
    },
    paginate (allItems) {
        let page = this.page;
        let perPage = this.perPage;
        let from = (page * perPage) - perPage;
        let to = (page * perPage);
        return  allItems.slice(from, to);
    },
    async getBrackets(){
      this.allItems = []
      await apolloClient.query({
        query: findBracketRoundQueryFile,
        variables: {     
            "league_id": parseInt(this.$route.params.lid),
            "group": parseInt(this.selectedFlight),
            "round_id": parseInt(this.selectedRound)
        }
      })
      .then((res) => {
        console.log(res.data.findBracketRoundNew)
        
        this.leagueName = res.data.findBracketRoundNew.bracket[0].header
        res.data.findBracketRoundNew.bracket[0].overview.forEach(element => {
          element.table = 'overview'
        });
        
        res.data.findBracketRoundNew.bracket[0].matches.forEach(element => {
          element.table = 'match'
        });

        this.allItems =  this.allItems.concat(res.data.findBracketRoundNew.bracket[0].overview);

        res.data.findBracketRoundNew.bracket[0].matches.forEach(matchesItem => {
          if(matchesItem.players1=='' && matchesItem.players2=='' && matchesItem.result==null){
            // fill up the table
            var itemsNr = this.itemsToFillUpArray(this.allItems.length,this.perPage)
            for (let i = 0; i < itemsNr; i++) {
              this.allItems = this.allItems.concat([{ players1: '', players2: '', result: undefined }]);
            }
          }else{
             this.allItems = this.allItems.concat(matchesItem);
          }
        });
        this.lastUpdate = convertTimestampToHumanDate(Date.now())
      })
    },
    itemsToFillUpArray(arrayLength, perPage) {
      if(arrayLength%perPage!=0){
        return perPage-arrayLength%perPage
      }
      return 0;
    },
  }
});

if (document.location.search.match(/type=embed/gi)) {
  window.parent.postMessage("resize", "*");
}

</script>


<style scoped>
.head{
    background-color: #e4e7ea;
    padding: 20px 0px 0px 20px;
}
#tournamentScreens{
    border-radius: 25px;    
    overflow: hidden;    
    border: solid 2px #e4e7ea;
    margin: 3%;
    font: 22px 'Calibri',Arial,Helvetica,Tahoma,Verdana,sans-serif;
}

.logo {
    height: 80px;
    width: 200px;
    background: transparent url(https://dgvscreens.cio.golf/cio/customer/logo.png) no-repeat top left;
    background-size: contain;
}
    

.hcp_font{
  font: 17px 'Open Sans', Arial, Helvetica, Tahoma, Verdana, sans-serif;
}

.bracketsRow{
    align-content: center;
    align-items: center;
  
    height: 60px;
    border: 1px solid #e4e7ea;
}

.bracketsCol{
    max-height: 600px;
    overflow: auto;
    border-right: 1px solid #e4e7ea;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.bracketsCol::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.bracketsCol {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}


.foot {    
    bottom: 0;
    left: 0;
    right: 0;
    margin-top: -4px;
    padding: 0 25px;
    line-height: 29px;
    
    background: #e4e7ea url(https://dgvscreens.cio.golf/cio/img/cio_logo.png) center center / 640px no-repeat;
    color: #000;
    height: 60px;
}

.holeCol {
  width: 32px !important; 
}

</style>