<template>
  <ion-page>
    <ion-content>
      <table>
        <tr>
          <td>
            <ion-select interface="action-sheet" v-model="selectedFlight" @ionChange="changeFlight($event.target.value)">
              <ion-select-option value="0">Flight A</ion-select-option>
              <ion-select-option value="1">Flight B</ion-select-option>
            </ion-select>
          </td>
          <td>
            <ion-select interface="action-sheet" v-model="selectedRound" @ionChange="changeRound($event.target.value)">
              <ion-select-option value="0">Runde 1</ion-select-option>
              <ion-select-option value="1">Runde 2</ion-select-option>
              <ion-select-option value="2">Runde 3</ion-select-option>
            </ion-select>
          </td>
        </tr>
      </table>
        <table class="ranking matchplay" v-for="(result, i) in bracketsFound" :key="i" :value="result">
          <colgroup>
          <col width="*">
          <col width="60">
          <col width="100">
          <col width="60">
          <col width="*">
          </colgroup>
          <tbody>
          <tr>
          <td colspan="5">
          <div class="roundInfo"> {{ result.header }}</div>
          </td>
          </tr>
          <tr>

          <th style="text-align:right">Team</th>
          <th style="text-align: center">Punkte</th>
          <th style="text-align: center">Ergebnis</th>
          <th style="text-align: center">Punkte</th>
          <th style="text-align:left">Team</th>
          </tr>

          <tr v-for="(match, j) in result.match" :key="j" :value="match" >
            <td style="text-align:right">
               {{ match.team1 }}
              </td>
              <td style="text-align: center;">{{ match.team1Points }}</td>
              <td style="text-align: center;">{{ match.team1Points }} : {{ match.team2Points }}</td>
              <td style="text-align: center;">{{ match.team2Points }}</td>
              <td>{{ match.team2 }}</td>
          </tr>
          <template  v-for="(match, j) in result.matches" :key="j" :value="match">
            <tr class="ranking matchplay" >
              <td style="text-align:right">
                <a v-if="match.result" class="openScore" @click="match.showScorecard == true ? match.showScorecard = false : match.showScorecard = true">{{ match.players1 }}</a>
                {{ match.result == undefined ? match.players1 : '' }}
              </td>
              <td style="text-align: center;">
                <a v-if="match.result" class="openScore" @click="match.showScorecard == true ? match.showScorecard = false : match.showScorecard = true">{{ match.points1 }}</a>
                {{ match.result == undefined ? match.result : '' }}
              </td>
              <td style="text-align: center;">
                <a v-if="match.result" class="openScore" @click="match.showScorecard == true ? match.showScorecard = false : match.showScorecard = true">{{ match.result }}</a>
                {{ match.result == undefined ? match.result : '' }}
              </td>
              <td style="text-align: center;">
                <a v-if="match.result" class="openScore" @click="match.showScorecard == true ? match.showScorecard = false : match.showScorecard = true">{{ match.points2 }}</a>
                {{ match.result == undefined ? match.result : '' }}
              </td>
              <td>
                <a v-if="match.result" class="openScore" @click="match.showScorecard == true ? match.showScorecard = false : match.showScorecard = true">{{ match.players2 }}</a>
                {{ match.result == undefined ? match.players2 : '' }}
              </td>
            </tr>
            <tr class="playerScore" v-if="match.result != undefined && match.showScorecard == true">
              <td colspan="5">
              <table class="playerScore matchplay">
                <tbody>
                <tr>
                  <td>Loch</td>
                  <td v-for="(hole, h) in match.numHoles" :key="h" :value="hole">{{ h+1 }}</td>
                </tr>
                <tr>
                  <td>{{match.players1}}</td>
                  <td v-for="(hole, h) in match.numHoles" :key="h" :value="hole">{{ (match.player1Scores[h] -  match.player2Scores[h]) > 0 ? (match.player1Scores[h] - match.player2Scores[h]) + ' auf' : '' }}</td>
                </tr>
                <tr class="as">
                  <td></td>
                  <td v-for="(hole, h) in match.numHoles" :key="h" :value="hole">{{ (match.player1Scores[h] != null && match.player1Scores[h] != -2 && match.player1Scores[h] == match.player2Scores[h]) ? 'AS' : '' }}</td>
                </tr>
                <tr>
                  <td>{{match.players2}}</td>
                  <td v-for="(hole, h) in match.numHoles" :key="h" :value="hole">{{ (match.player2Scores[h] - match.player1Scores[h]) > 0 ? (match.player2Scores[h]  - match.player1Scores[h]) + ' auf' : '' }}</td>
                </tr>
                </tbody>
              </table>
              </td>
            </tr>
          </template>
          </tbody>
        </table>
    </ion-content>
  </ion-page>
</template>

<script>
import { toastController, modalController, loadingController, IonTitle, IonButton, IonToolbar, IonHeader, 
IonMenuButton, IonButtons, IonGrid, IonImg, IonCol, IonRow, IonItemDivider, IonSearchbar, IonCheckbox,
IonApp, IonContent, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle, 
IonNote, IonRouterOutlet, IonSplitPane, IonSelect, IonSelectOption, IonAvatar, IonFabList, 
IonFabButton, IonFab, IonCardContent, IonCardHeader, IonCardTitle, IonCard, IonPage, isPlatform } from '@ionic/vue';
import { defineComponent, ref, watch } from 'vue';
import { useQuery, useResult } from '@vue/apollo-composable'
import findBracketRoundQueryFile from '../graphql/findBracketRound.query.gql'
import { useRoute, useRouter } from 'vue-router';
import { chevronBackOutline, chevronForwardOutline, arrowDownSharp, arrowUpSharp } from 'ionicons/icons';
import * as dayjs from 'dayjs';
import VueScrollingTable from "vue-scrolling-table"
import "/node_modules/vue-scrolling-table/dist/style.css"

export default defineComponent({
  name: 'Home',
  components: {
    IonApp, IonImg, IonAvatar, IonPage,
    IonContent, 
    IonIcon, 
    IonItem, 
    IonLabel, VueScrollingTable,
    IonList, 
    IonListHeader, 
    IonMenu, 
    IonMenuToggle, 
    IonNote, 
    IonRouterOutlet, 
    IonSplitPane,
    IonSearchbar,
    IonItemDivider,
    IonGrid, 
    IonCol, 
    IonRow,
    IonTitle, IonButton, IonToolbar, IonHeader, IonMenuButton, IonButtons, IonSelect, IonSelectOption,
    IonFabList, IonFabButton, IonFab, IonCardContent, IonCardHeader, IonCardTitle, IonCard, IonCheckbox
  },
  data() {
    return {
      logo: require('@/assets/images/logo/nrwlogo.png'),
      weekDays: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']
    }
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const selectedFlight = ref(route.params.groupid)
    const selectedRound = ref(route.params.roundid)
    let currentTicker = ref('')

    const {onResult, result: findBracketRoundResult } = useQuery(findBracketRoundQueryFile, () => ({ group: parseInt(route.params.groupid), round_id: parseInt(route.params.roundid), league_id: parseInt(route.params.lid) }), { fetchPolicy: 'no-cache' } )
    const bracketsFound = useResult(findBracketRoundResult, null, data => data.findBracketRound.bracket)
    currentTicker = useResult(findBracketRoundResult, null, data => data.findBracketRound.currentTicker)

    return {
      bracketsFound, chevronBackOutline, chevronForwardOutline, dayjs, arrowDownSharp, arrowUpSharp, isPlatform, selectedFlight, selectedRound, currentTicker
    }
  },
  methods: {
    showScorecard() {
      console.log('here')
    },
    changeFlight(flight) {
      this.$router.push('/' + this.$route.params.lid + '/' + flight  + '/' + this.$route.params.roundid)
    },
    changeRound(round) {
      this.$router.push('/' + this.$route.params.lid + '/' + this.$route.params.groupid  + '/' + round)
    },
  }
});

if (document.location.search.match(/type=embed/gi)) {
  window.parent.postMessage("resize", "*");
}

</script>

<style scoped>

/* ############## ticker.css ###########*/
.ticker-container {
  border: 0px solid;
  overflow: hidden;
  margin-left: 0px;
  margin-right: 0px;
  color: red;
}

.ticker-text {
  height: 150%;
  font-size: 24px;
  white-space: nowrap;
  margin-top: 56px; /* Dung: added */
}


</style>